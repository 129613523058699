.settings-header {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.settings-body {
  display: flex;
  flex-wrap: wrap;
}

.settings-body-list {
  margin-top: 20px;
  min-width: 350px;
  width: 500px;
  margin-right: 40px;
}

.settings-body-list-header {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 18px;
}

.settings-input-box-header {
  min-width: 300px;
  width: 400px;
}

.settings-input-box {
  margin: 10px;
  display: flex;
  align-items: center;
}

.settings-input-label {
  width: 110px;
  font-weight: 600;
  margin-right: 10px;
}

.settings-input {
  flex-grow: 1;
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
  background-color: var(--axis-background);
  box-shadow: 1px 1px 2px var(--disable-color);
}

.settings-input:focus {
  outline: none;
  background-color: var(--ground_color);
}

.settings-connections {
  display: flex;
  flex-wrap: wrap;
}

.settings-actions {
  margin: 10px;
  width: 350px;
}

.settings-block-header {
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid var(--primary-color);
}

.settings-block-list {
  max-height: 400px;
  overflow-y: auto;
}

.settings-block-list-row {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: var(--axis-background);
  border-radius: 5px;
  padding: 10px;
}

.settings-block-list-row-overlay {
  position: absolute;
  display: grid;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgb(221, 218, 218);
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
}

.settings-block-list-row:hover .settings-block-list-row-overlay {
  opacity: 0.8;
}

.settings-block-list-icon-box {
  color: black;
  justify-self: center;
  align-self: center;
}

.settings-block-list-pointer {
  margin-right: 15px;
  font-size: 20px;
}

.settings-btn-box {
  margin-top: 40px;
}

/* Connections Settings */
.settings-edit-header {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
}

.settings-actions-list {
  margin: 20px auto;
}

.settings-edit-input {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 250px;
}

.settings-edit-select {
  padding: 10px !important;
}

.settings-select-box {
  width: 250px;
}

.settings-case-input {
  width: 500px;
}

.settings-actions-list-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.settings-actions-list-row-name {
  width: 110px;
  margin-right: 10px;
  font-weight: 600;
}

.settings-actions-wide {
  width: 190px;
}

.settings-date {
  margin-top: 20px;
}

.settings-buttons-box {
  display: grid;
  grid-template-columns: 150px 150px;
}

.settings-buttons-box-one {
  display: flex;
  justify-content: center;
  max-width: 750px;
}

.settings-btn {
  margin: 10px;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px var(--disable-color);
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--ground_color);
  justify-self: center;
  height: fit-content;
}

.settings-btn:hover:enabled {
  box-shadow: 3px 3px 7px var(--disable-color);
}

.settings-btn:disabled {
  background-color: var(--disable-color);
  cursor: not-allowed;
}

.settings-update-btn:hover:enabled {
  background-color: var(--secondary-color);
}

.settings-delete-btn {
  background-color: var(--error-color);
}

.settings-delete-btn:hover:enabled {
  background-color: var(--error-color-hover);
}

.settings-delete-schedule {
  margin-left: 50px;
  max-width: 120px;
}

.settings-disable-btn {
  background-color: var(--secondary-color);
}

.settings-disable-btn:hover {
  background-color: var(--secondary-hover);
}

.settings-add-btn {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 7px 20px;
  margin-bottom: 20px;
  font-weight: 600;
  cursor: pointer;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.settings-add-btn-icon {
  margin-right: 10px;
  font-size: 22px;
}

.settings-add-btn:hover {
  box-shadow: 1px 1px 2px var(--secondary-hover);
}

.settings-input-number {
  -moz-appearance: none;
}

.settings-input-number::-webkit-inner-spin-button,
.settings-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Account Settings */

.settings-account-box {
  margin: 20px;
}

.settings-account-table {
  overflow-x: auto;
}

.settings-account-box-title {
  margin-bottom: 20px;
  margin-left: 20px;
}

.settings-account-row {
  display: grid;
  margin-bottom: 10px;
}

.settings-account-row-header {
  margin-bottom: 20px;
}

.settings-account-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 220px;
}

.settings-account-ns-cell {
  width: 240px;
}

.settings-account-input {
  width: 200px;
  padding: 15px 10px;
  font-size: 15px;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  box-shadow: 1px 1px 2px var(--disable-color);
  text-align: center;
}

.settings-account-input:focus {
  outline: none;
  border: 2px solid var(--secondary-color);
  box-shadow: 1px 1px 2px var(--secondary-color);
}

.settings-account-input:disabled {
  background-color: var(--axis-background);
  color: var(--primary-color);
}

.settings-account-description {
  padding: 5px;
  height: 100%;
  width: 250px;
  background-color: var(--axis-background);
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
}

.settings-account-description-cell {
  width: 250px;
}

.settings-account-arrow {
  font-size: 30px;
  margin-left: 10px;
}

.settings-addfile-container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.settings-addfile-box {
  border: 1px dashed var(--disable-color);
  padding: 10px 30px;
  font-size: 14px;
  text-align: center;
}

.settings-attached-box {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 10px;
}

.settings-attached-file {
  display: flex;
  align-items: center;
  font-size: 11px;
  background-color: var(--axis-background);
  padding: 3px 7px;
  border-radius: 5px;
  margin: 5px 0;
  box-shadow: 1px 1px 2px var(--disable-color);
  width: fit-content;
}

.settings-attached-delete {
  font-size: 18px;
  color: var(--error-color);
  cursor: pointer;
  margin-left: 5px;
}

.settings-attachments-row {
  margin-top: 10px;
  align-self: flex-start;
}

@media (min-width: 768px) {
  .settings-buttons-box {
    margin-top: 30px;
    grid-template-columns: 200px 200px;
  }

  .settings-buttons-box-one {
    margin-top: 30px;
  }
}

@media (min-width: 1024px) {
  .settings-actions {
    margin: 10px 30px;
    width: 450px;
  }
}

/* Add Action form */
.settings-actions-description {
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  max-width: 250px;
}
