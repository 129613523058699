:root {
  --ground_color: white;
  --primary-color: #2f3440;
  --secondary-color: #dd7e43;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --error-color: #ff3333;
  --error-color-hover: rgb(255, 100, 100);
  --success-color: #28a745;
  --disable-color: #a7a19d;
  --back-color: #f0f0f0;
  --axis-background: #f6f6f6;
  --info-color: #f1f1f1;
  --chart-green-color: #91c559;
  --chart-orange-color: #ffc756;
  --chart-blue-color: #577ecb;
  --shadows-color: #c2c2c2;
  --secondary-hover: #e09262;
  --primary-hover: #59606e;
  --search-hover: rgba(224, 146, 98, 0.3);
  --npc-green: rgba(3, 151, 37, 0.5);
  --npc-cell-green: rgba(1, 175, 42, 0.2);
  --npc-blue: rgba(0, 87, 248, 0.5);
  --npc-violet: #bc9ed7;
  --npc-cell-violet: #d9c0ee;
  --npc-cell-blue: rgba(0, 87, 248, 0.2);
  --npc-cell-orange: #f5b48c;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto';
  font-size: 1rem;
  background-color: var(--ground_color);
  color: var(--primary-color);
  overflow-y: auto;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--ground_color);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 20px;
  font-weight: 600;
}

h2 {
  font-size: 18px;
  font-weight: 600;
}

h3,
h4 {
  font-size: 15px;
}

/* Images */
.responsive {
  max-width: 100%;
  height: auto;
}

.round-image {
  border-radius: 50%;
}

.nav-avatar {
  width: 50px;
  margin-right: 15px;
}

.small-image {
  width: 50px;
  margin-right: 10px;
  margin-left: 50px;
}

.drawer-avatar {
  display: flex;
  align-items: center;
}

/* Alerts */
.alert-container {
  display: grid;
  grid-gap: 10px;
  top: 20px;
  position: fixed;
  right: 20px;
  z-index: 30;
}

/* Spinners block */
.spinner-container-page {
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: center;
}

/* Buttons */
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-light {
  background: var(--light-color);
  color: #333;
}

.btn-light:hover {
  background: rgb(226, 224, 224);
}

.user-form-buttons {
  margin-top: 10px;
}

/* Icons */
.nav-mi-icon {
  font-size: 30px !important;
  cursor: pointer;
  margin: 10px;
  color: var(--ground_color);
}

.nav-user-icon {
  width: 40px !important;
  margin-right: 10px;
}

.black-icon {
  color: var(--primary-color);
}

.info-icon {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px 6px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--info-color);
  color: var(--secondary-color);
}

.status-container {
  display: flex;
  align-items: center;
}

.status-icon {
  margin-right: 5px;
  font-size: 20px;
}

.warning-icon {
  color: var(--chart-orange-color);
}

.active-icon {
  cursor: pointer;
  font-size: 18px;
}

.connector-icon-new {
  padding: 2px 3px;
  border-radius: 4px;
  background-color: var(--chart-green-color);
  margin-right: 10px;
  font-size: 12px;
  word-break: keep-all;
}

.connector-icon-old {
  padding: 2px 3px;
  border-radius: 4px;
  background-color: var(--chart-orange-color);
  margin-right: 15px;
  font-size: 12px;
  word-break: keep-all;
}

/* Text */
.bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.icon-name-text {
  margin-left: 5px;
}

.error-text {
  color: var(--error-color);
}

.noselect {
  user-select: none;
}

.required-icon {
  font-weight: 600;
  color: var(--error-color);
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.underlined {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

/* Common styles */
.hidden {
  display: none;
}

.white-link {
  color: var(--ground_color);
}

.primary-link {
  color: var(--primary-color);
}

.selected-category {
  background-color: rgb(88, 88, 88);
}

/* Tabs */
.disabled-tab {
  display: none !important;
}

.active-tab {
  display: block;
}

.tab-icon {
  margin-right: 5px;
  font-size: 16px;
}

/* Date Picker */
.date-picker {
  padding: 5px 15px;
  font-size: 15px;
  letter-spacing: 1px;
  width: 130px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
}

.date-picker-arrow {
  width: 15px !important;
  height: 15px !important;
  margin-right: 10px;
  margin-left: 10px;
  align-self: center;
}

/* Pagination */
.admin-pagination > li > button {
  color: var(--primary-color) !important;
}

.admin-pagination > li > div {
  color: var(--primary-color) !important;
}

.admin-pagination > li > .Mui-selected {
  background-color: rgba(245, 127, 32, 0.6);
}

/* Sceleton loaders */
.search-container {
  margin-left: 30px;
  margin-right: 30px;
  width: 250px;
  height: 30px !important;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #f0ecf0;
  border-radius: 5px;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 5s infinite;
  -webkit-animation: shimmer 5s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Links */
.active-link {
  cursor: pointer;
  color: var(--npc-blue);
}

/* Checkbox */
.main-checkbox {
  position: absolute;
  opacity: 0;
  display: none;
}

.main-checkbox:checked + label svg path {
  stroke-dashoffset: 0;
}

.main-checkbox:focus + label {
  transform: scale(1.03);
}

.main-checkbox + label {
  display: block;
  border: 2px solid var(--disable-color);
  width: var(--d);
  height: var(--d);
  cursor: pointer;
  /* transition: all 0.2s ease; */
}

.main-checkbox + label:active {
  transform: scale(1.05);
}

.main-checkbox + label svg {
  pointer-events: none;
}

.main-checkbox + label svg path {
  fill: none;
  stroke: var(--secondary-color);
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 101;
  /* transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91); */
}

/* File Btn */
input[type='file'] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.upload-button {
  color: rgba(0, 87, 248, 0.5);
  cursor: pointer;
  margin-right: 5px;
}

.upload-button:hover {
  color: rgba(58, 125, 250, 0.5);
}

/* Sceleton Loader */

.table-skeleton-box {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.table-loading-container {
  margin: 10px;
  width: 130px;
  height: 20px !important;
  border-radius: 10px !important;
}

.cell-skeleton-container {
  width: 60px;
}

@media (min-width: 768px) {
  .table-loading-container {
    margin: 20px;
    width: 150px;
  }
}

/* Drag and Drop */
.dragable-box {
  border: 2px dashed var(--secondary-color) !important;
  box-shadow: 2px 2px 4px var(--secondary-color);
}

/* select MU */
.select-list {
  max-height: 400px !important;
}

/* Page 404 */
.not-found-box {
  display: flex;
  width: 100%;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.not-found-header {
  display: flex;
  align-items: center;
  margin: 20px;
}

.not-found-message {
  word-break: break-all;
}

.not-found-icon {
  color: var(--error-color);
  font-size: 30px;
  margin-right: 15px;
  min-width: 30px;
}

.not-found-btn {
  width: 120px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  font-weight: 600;
  color: var(--ground_color);
  border-radius: 5px;
  text-align: center;
}

.not-found-btn:hover {
  background-color: var(--primary-hover);
}

.not-found-url {
  color: var(--npc-blue);
}

.print-icon {
  cursor: pointer;
  height: 30px !important;
  width: 30px !important;
  color: var(--primary-color);
}

.print-icon-disabled {
  cursor: not-allowed;
  height: 30px !important;
  width: 30px !important;
  color: var(--disable-color);
}

.print-icon:hover {
  color: var(--secondary-color);
}

/* Table Styles */
.standard-table-header {
  display: grid;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid var(--primary-color);
}

.standard-table-row {
  display: grid;
  border-bottom: 1px solid var(--disable-color);
}

.table-cell-align-left {
  justify-content: left !important;
  text-align: left !important;
}

.main-table-header {
  display: grid;
  border-bottom: 2px solid var(--primary-color);
  margin-bottom: 10px;
  margin-right: 12px;
}

.main-table-row {
  position: relative;
  display: grid;
  border-bottom: 1px solid var(--disable-color);
  font-size: 14px;
}

.main-table-row:hover .settings-block-list-row-overlay {
  opacity: 0.6;
}

.main-header-cell {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}

.table-filter-header {
  cursor: pointer;
  user-select: none;
}

.table-filter-header:hover + .filter-arrow-hover {
  opacity: 0.5;
}

.filter-arrow-hover {
  width: 15px !important;
  height: 15px !important;
  margin-left: 5px;
  opacity: 0;
}

.filter-arrow {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;
  opacity: 0;
}

.active-arrow-asc {
  opacity: 1;
  transform: rotate(0deg);
}

.active-arrow-dsc {
  opacity: 1;
  transform: rotate(180deg);
}

/* reCaptcha */
.grecaptcha-badge {
  visibility: hidden;
}
