.modal {
  display: none; /*Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 12; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  padding-bottom: 100px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-container {
  position: relative;
  background-color: #fefefe;
  color: var(--dark-color);
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 900px;
  height: 75vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-schedule-container {
  max-width: 500px;
  height: 510px;
}

.modal-header {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  border-bottom: 1px solid var(--primary-color);
}

.modal-title {
  text-align: center;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 600;
}

.modal-body {
  display: grid;
  padding: 10px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.modal-body-header {
  display: flex;
  flex-wrap: wrap;
}

.modal-body-header-param {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.modal-body-header-name {
  font-weight: 600;
  margin-right: 10px;
}

.modal-body-header-value {
  padding: 5px 10px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
}

.modal-body-param {
  margin: 20px 10px;
  display: flex;
  flex-wrap: wrap;
}

.modal-body-message {
  width: fit-content;
  max-height: 400px;
  max-width: 700px;
  padding: 5px 10px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.modal-actions-list {
  margin: 20px auto;
}

/* Confirmation Modal */
.modal-container-confirm {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fefefe;
  color: var(--dark-color);
  margin: auto;
  padding: 15px;
  border-radius: 5px;
  max-width: 500px;
  height: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-confirm-icon {
  font-size: 55px;
  color: var(--error-color);
}

.modal-confirm-message {
  font-size: 18px;
  margin: 30px;
}

.modal-confirm-buttons {
  display: flex;
  align-items: center;
}

.modal-btn {
  margin: 10px;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px var(--disable-color);
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--ground_color);
  justify-self: center;
}

.modal-btn:hover {
  box-shadow: 3px 3px 7px var(--disable-color);
}

.modal-cancel-btn {
  color: var(--primary-color);
  background-color: var(--ground_color);
  border: 1px solid var(--disable-color);
}

.modal-cancel-btn:hover {
  color: var(--disable-color);
  border: 1px solid var(--primary-color);
}

.modal-update-btn:hover {
  background-color: var(--secondary-color);
}

.modal-delete-btn {
  background-color: var(--error-color);
}

.modal-delete-btn:hover {
  background-color: var(--error-color-hover);
}

.schedule-container {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 450px;
}

.schedule-sublist-container {
  min-height: 120px;
}

.schedule-main-list {
  margin-top: 10px;
}

.schedule-main-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin-bottom: 12px;
}

.schedule-row-action-name {
  width: 250px;
  font-size: 14px;
}

.schedule-row-name {
  margin-right: 20px;
  min-width: 100px;
}

.schedule-select-time {
  width: 60px;
}

.schedule-add-info {
  margin-left: 10px;
}

.schedule-btn-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.schedule-subrow {
  margin-bottom: 5px;
}

.schedule-time-box {
  width: 100px;
}

.schedule-time-picker {
  width: 90px;
  padding: 8px;
  text-align: center;
  border: 1px solid var(--shadows-color);
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 15px;
}

.schedule-time-icon {
  font-size: 30px;
  margin-left: 10px;
}

.schedule-row-details {
  margin-right: 20px;
  margin-left: 20px;
}

.schedule-cron-info {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
}

.schedule-cron-link {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .schedule-box {
    min-width: 400px;
  }

  .schedule-sublist-container {
    min-height: 160px;
  }

  .schedule-row-details {
    margin-left: 190px;
  }
}
