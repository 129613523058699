.pageError {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
}

.pageErrorMessage {
  font-weight: 600;
  font-size: 18px;
}
