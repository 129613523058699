.selectBox {
  width: 250px;
  position: relative;
}

.filterSelectLine {
  text-align: left;
  list-style: none;
  cursor: pointer;
  padding: 10px 15px;
  user-select: none;
}

.filterSelectLine:hover {
  background-color: rgb(245, 245, 245);
}

.filterSelectLineActive {
  background-color: rgb(219, 219, 219);
}

.filterSelectLineActive:hover {
  cursor: auto;
  background-color: rgb(219, 219, 219);
}

.filterSelectList {
  position: absolute;
  overflow-y: auto;
  max-height: 250px;
  min-height: 15px;
  top: 100%;
  left: -5%;
  background-color: var(--ground_color);
  z-index: 99;
  border-radius: 5px;
  width: fit-content;
  min-width: 110%;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.filterSelectListActive {
  visibility: visible;
  opacity: 1;
}

.filterSelectArrow {
  font-size: 20px;
  transition: transform 0.15s ease;
}

.filterSelectArrowUp {
  transform: rotate(-180deg);
}

.filterSelectValue {
  text-align: left;
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.filterSelectBox {
  display: flex;
  align-items: center;
  border: 1px solid var(--disable-color);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 3px;
  user-select: none;
  text-overflow: ellipsis;
}

.selectIconBox {
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
