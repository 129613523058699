.searchContainer {
  position: relative;
  margin: 0 20px;
  background-color: var(--ground_color);
  border: 1px solid var(--disable-color);
  border-radius: 5px;
}

.search {
  display: flex;
  align-items: center;
  padding: 7px 10px;
}

.searchInput {
  width: 0px;
  border: none;
  font-size: 16px;
  transition: 0.3s;
}
.searchInputFull {
  width: 200px;
  margin-right: 10px;
}

.searchInput:focus {
  outline: none;
}

.searchResults {
  display: grid;
  position: absolute;
  top: 38px;
  max-height: 300px;
  width: 330px;
  background-color: var(--ground_color);
  border: 1px solid var(--primary-color);
  z-index: 1;
  overflow-y: auto;
}

.searchResultsRow {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 7px 15px;
  color: var(--primary-color);
}

.searchResultsRow:hover {
  cursor: pointer;
  background-color: var(--search-hover);
}

.searchNoresults {
  font-size: 14px;
  padding: 7px 15px;
}

.searchIcon {
  font-size: 20px;
  cursor: pointer;
}

.searchIcon:hover {
  color: var(--secondary-color);
}
