.feeds-container {
  display: grid;
  overflow-x: auto;
  max-width: fit-content;
}

.feeds-center-header {
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 200px 80px repeat(6, 150px);
  font-size: 14px;
  font-weight: 600;
  border: 2px solid var(--disable-color);
  margin-right: 13px;
}

.feeds-table-body {
  max-height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.feeds-center-row {
  display: grid;
  width: min-content;
  min-height: 45px;
  grid-template-columns: 200px 80px repeat(6, 150px);
  border-left: 2px solid var(--disable-color);
  border-bottom: 2px solid var(--disable-color);
  border-right: 2px solid var(--disable-color);
}

.feeds-center-row:hover {
  background-color: rgb(231, 231, 231);
}

.feeds-table-two-rows {
  grid-row: 1/3;
}

.feeds-table-feeds-columns {
  grid-column: 3/9;
  grid-row: 1/2;
}

.feeds-download-icon {
  color: var(--chart-blue-color);
  font-size: 25px;
  cursor: pointer;
}

.feeds-download-icon:hover {
  color: var(--npc-blue);
}

.feeds-download-icon-loading {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  /* color: var(--chart-blue-color);
  font-size: 25px;
  cursor: wait; */
}

.feeds-border-right {
  border-right: 2px solid var(--disable-color);
}

.feeds-border-bottom {
  border-bottom: 2px solid var(--disable-color);
}

.feeds-active-cell,
.feed-active-cell {
  cursor: pointer;
  color: var(--primary-color);
}

.feeds-active-cell:hover {
  background-color: var(--npc-blue);
  font-weight: 600;
  color: var(--ground_color);
}

.feed-connected-cell {
  background-color: rgb(173, 247, 173);
  font-weight: 600;
}

.feed-active-cell:hover {
  background-color: rgb(173, 247, 173);
  font-weight: 600;
}

.feed-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.feed-header-table-box {
  overflow-x: auto;
  border-top: 2px solid var(--disable-color);
}

.feed-header-table {
  display: grid;
  grid-template-rows: 50px 40px;
  grid-template-columns: 150px repeat(7, 130px);
  font-size: 14px;
  border-left: 2px solid var(--disable-color);
}

.feed-header-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.feed-header-cell {
  border-bottom: 2px solid var(--disable-color);
  border-right: 2px solid var(--disable-color);
}

.feed-btn {
  display: flex;
  align-items: center;
  width: 200px;
  min-width: 200px;
  height: 35px;
  justify-content: center;
  background-color: var(--chart-blue-color);
  margin: 10px;
  border: none;
  border-radius: 5px;
  color: var(--ground_color);
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}

.feed-btn-wide {
  width: 250px;
  min-width: 250px;
}

.feed-filters-btn {
  background-color: var(--disable-color);
}

.feed-selected-btn {
  background-color: var(--secondary-color);
}

.feed-btn:hover {
  background-color: #4673cc;
}

.feed-filters-btn:hover {
  background-color: #ada8a3;
}

.feed-selected-btn:hover {
  background-color: var(--secondary-hover);
}

.feed-btn:disabled {
  background-color: var(--disable-color);
  cursor: wait;
}

.feed-btn-icon {
  margin-right: 10px;
}

.feed-filter-header {
  margin-top: 20px;
  margin-bottom: 10px;
}

.feed-brands-input {
  width: 250px;
  margin-top: 10px 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  min-width: 250px;
}

.feed-filter-box {
  display: grid;
  justify-content: center;
}

.feed-other-filters {
  position: relative;
}

.feed-selected-container {
  position: relative;
}

.feed-filter-row {
  position: absolute;
  top: 50px;
  background-color: var(--axis-background);
  border-radius: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

.feed-selected-box {
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
  padding: 5px 10px;
}

.feed-filter-title {
  font-weight: 600;
  margin-right: 10px;
  width: 90px;
}

.feed-filter-container {
  display: flex;
  align-items: flex-end;
  margin: 10px 20px;
  width: 320px;
}

.feed-filter-input {
  width: 190px;
}

.feed-filter-icon {
  margin-left: 15px;
  font-size: 20px;
}

.feed-active-btn {
  color: var(--npc-blue);
  text-decoration: underline;
  cursor: pointer;
  margin: 10px;
}

.feed-filter-list-icon {
  margin-right: 10px;
  font-size: 23px !important;
}

.feed-items-header {
  display: grid;
  grid-template-columns: 80px 150px 220px 120px 190px 120px 100px 100px 120px repeat(
      3,
      100px
    );
  font-size: 14px;
  font-weight: 600;
  border: 2px solid var(--disable-color);
  margin-right: 13px;
}

.feed-items-short {
  grid-template-columns: 80px 120px 190px 120px 100px 100px 120px repeat(
      3,
      100px
    ) !important;
}

.feed-items-body {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.feed-items-row {
  display: grid;
  width: min-content;
  grid-template-columns: 80px 150px 220px 120px 190px 120px 100px 100px 120px repeat(
      3,
      100px
    );
  font-size: 13px;
  border-left: 2px solid var(--disable-color);
  border-bottom: 2px solid var(--disable-color);
  border-right: 2px solid var(--disable-color);
}

.feed-items-row:hover {
  background-color: rgb(231, 231, 231);
}

.feed-pagination-box {
  margin: 10px;
}

.feed-actions-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.feed-selected-row {
  display: flex;
  align-items: center;
  margin: 7px 0;
}

.feed-selected-name {
  border: 1px solid var(--disable-color);
  border-radius: 3px;
  font-size: 14px;
  flex-grow: 1;
  padding: 5px;
}

.feed-selected-remove {
  font-size: 20px;
  color: var(--error-color);
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .feed-filter-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .feed-pagination-box {
    margin-left: 50px;
  }

  .feed-actions-buttons {
    min-width: 450px;
    height: 55px;
  }
}

@media (min-width: 1540px) {
  .feed-header-buttons {
    display: block;
  }
}
