.container {
  max-width: 500px !important;
  height: 350px !important;
}

.formContainer {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 300px;
}

.btnBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  margin-top: 50px;
}

.list {
  margin-top: 10px;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin-bottom: 12px;
}

.formRowName {
  margin-right: 20px;
  min-width: 100px;
  font-weight: 600;
}

.formRowValue {
  width: 250px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .formBox {
    min-width: 400px;
  }
}
