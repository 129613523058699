.input {
  border: 1px solid var(--disable-color);
  padding: 10px 15px;
  font-size: 14px;
  margin: 5px 0;
  width: 100%;
  outline: none;
}

.inputLogin {
  background-color: #eee;
  border: 1px solid #eee;
}

.input:focus {
  border: 1px solid var(--secondary-color);
}

.error {
  width: 230px;
  color: var(--error-color);
  font-size: 12px;
  height: 15px;
  text-align: left;
}
