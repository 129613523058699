.actions-noactions {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.actions-header-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 3px solid var(--disable-color);
  box-shadow: 2px 2px 4px var(--disable-color);
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}

.actions-header-btn:hover {
  background-color: var(--axis-background);
}

.actions-table-box {
  display: none;
  margin: 20px 0;
  overflow-x: auto;
}

.action-header-box {
  display: grid;
  margin-bottom: 20px;
  overflow-x: auto;
  max-width: 880px;
}

.action-header-box-old {
  max-width: 680px;
}

.action-tab-icon {
  margin-right: 2px;
  font-size: 18px;
}

.actions-active {
  display: grid;
}

.actions-table-header-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  padding: 5px;
}

.actions-table-body {
  max-height: 400px;
  overflow-y: auto;
  font-size: 13px;
  overflow-x: hidden;
}

.actions-table-body-cell {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  text-align: center;
  word-break: break-word;
}

.actions-table-body-cell-left {
  justify-content: flex-start;
  text-align: left;
}

.actions-icon {
  cursor: pointer;
}

.actions-icon-loading {
  cursor: wait;
}

.actions-icon-stop {
  font-size: 20px;
}

.actions-icon-stop:hover {
  color: var(--error-color);
}

.actions-connections-box {
  display: flex;
  flex-wrap: wrap;
}

.actions-connection-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
  border: 2px solid var(--shadows-color);
  box-shadow: 1px 1px 2px var(--disable-color);
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 15px;
}

.actions-connection-box:hover {
  box-shadow: 2px 2px 4px var(--disable-color);
}

.actions-connection-logo {
  display: flex;
  height: 40px;
}

.actions-connection-logo-img {
  height: 40px;
  width: auto;
}

.actions-connection-logo-status {
  height: 40px;
  width: auto;
  margin-left: auto;
}

.actions-connection-logo-icon {
  font-size: 40px;
  margin-left: auto;
}

.actions-connection-name {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.actions-connection-settings {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.connection-settings-icon {
  margin-left: 10px;
  font-size: 30px;
}

.connection-settings-icon:hover {
  color: var(--secondary-color);
}

.actions-connection-actions {
  flex-grow: 1;
  text-align: right;
}

.actions-connection-actions-value {
  font-weight: 600;
  margin-left: 5px;
}

/* Action page styles */
.action-breadcrumb-btn {
  display: flex;
  border: 1px solid var(--primary-color);
  padding: 5px 10px;
  align-items: center;
  font-weight: 600;
  border-radius: 5px;
  margin-left: 15px;
}

.action-breadcrumb-btn:hover {
  border: 1px solid var(--primary-hover);
  color: var(--primary-hover);
}

.action-breadcrumb-icon {
  font-size: 20px;
  margin-right: 10px;
}

.action-tabs-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--disable-color);
  margin-bottom: 20px;
}

.subtabs-container {
  flex-wrap: nowrap;
  border-bottom: none;
  margin-bottom: 10px;
  overflow-x: auto;
}

.action-tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  font-weight: 600;
  color: var(--primary-color);
}

.action-tab-container:hover {
  cursor: pointer;
  color: var(--disable-color);
}

.action-tab-active {
  border: 1px solid var(--disable-color);
  border-bottom: 1px solid var(--ground_color);
  margin-bottom: -1px;
}

.action-tab-active:hover {
  cursor: auto;
  color: var(--primary-color);
}

.subtab-tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(238, 238, 238);
}

.subtab-tab-container:hover {
  cursor: pointer;
  background-color: rgb(209, 208, 208);
}

.subtab-tab-active {
  background-color: rgb(252, 153, 41);
  color: var(--ground_color);
}

.subtab-tab-active:hover {
  cursor: auto;
  color: var(--ground_color);
  background-color: rgb(252, 153, 41);
}

.action-logs-body {
  display: grid;
  overflow-x: auto;
}

.action-logs-table-body {
  max-height: 290px;
  overflow-y: auto;
  font-size: 13px;
  overflow-x: hidden;
}

.action-logs-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.action-logs-date-pickers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
}

.action-date-pickers-header {
  margin-right: 10px;
  font-weight: 600;
}

.action-date-pickers-container {
  display: flex;
  align-items: center;
}

.action-pagination-box {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.action-logs-table-header-new {
  display: grid;
  grid-template-columns: 100px 200px 120px 120px minmax(300px, 1fr);
  border-bottom: 1px solid var(--primary-color);
}

.action-logs-table-row-new {
  position: relative;
  display: grid;
  grid-template-columns: 100px 200px 120px 120px minmax(300px, 1fr);
  border-bottom: 1px solid var(--disable-color);
}

.action-logs-table-header {
  display: grid;
  grid-template-columns: 100px 200px minmax(300px, 1fr);
  border-bottom: 1px solid var(--primary-color);
}

.action-logs-table-row {
  position: relative;
  display: grid;
  grid-template-columns: 100px 200px minmax(300px, 1fr);
  border-bottom: 1px solid var(--disable-color);
}

.action-logs-table-row:hover .action-item-overlay {
  opacity: 0.5;
}

.actions-link-cell {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.actions-link-cell:hover {
  color: var(--secondary-color);
}

.action-item-overlay {
  position: absolute;
  grid-column: 1/7;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgb(219, 218, 218);
  cursor: pointer;
}

.action-message-icon {
  color: black;
}

.action-select-type {
  width: 150px;
}

.action-select-type div {
  padding-left: 10px;
}

.action-select-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px;
}

.action-select-name {
  margin-right: 10px;
}

/* Action Settings */
.action-settings-container {
  display: flex;
  flex-wrap: wrap;
}

.action-settings-param {
  margin: 10px 20px;
  display: flex;
  flex-wrap: wrap;
}

.action-settings-name {
  font-weight: 600;
  margin-right: 10px;
  width: 120px;
}

.action-settings-value {
  padding: 5px 10px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
  overflow-y: auto;
  max-height: 300px;
  word-wrap: break-word;
  word-break: break-all;
}

.action-settings-box {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px var(--disable-color);
  height: fit-content;
  overflow-x: auto;
}

.action-settings-box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--disable-color);
  opacity: 0.3;
  overflow-y: auto;
}

.action-settings-header {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.action-settings-list-header {
  display: grid;
  border-top: 1px solid var(--disable-color);
  border-left: 1px solid var(--disable-color);
  width: max-content;
}

.action-settings-list-header2 {
  display: grid;
  font-weight: 500;
}

.action-settings-list-header-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  background-color: var(--axis-background);
  border-bottom: 1px solid var(--disable-color);
  border-right: 1px solid var(--disable-color);
}

.action-settings-list-header-cell2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid var(--disable-color);
}

.action-settings-list {
  width: max-content;
  font-size: 14px;
  max-height: 400px;
  overflow-y: auto;
}

.action-settings-row {
  display: grid;
  align-items: center;
  margin-top: 10px;
  box-shadow: 1px 1px 2px var(--disable-color);
}

.action-settings-list2 {
  font-size: 14px;
}

.action-settings-header2 {
  text-decoration: underline;
  font-weight: 500;
  padding: 5px 10px;
  word-break: break-all;
  font-size: 16px;
}

.action-settings-row2 {
  display: grid;
  align-items: center;
  margin-top: 5px;
  box-shadow: 1px 1px 2px var(--disable-color);
}

.action-settings-row-name {
  padding: 5px 10px;
  word-break: break-all;
}

.actions-settings-input {
  flex-grow: 1;
  padding: 7px 10px;
  font-size: 15px;
  border: none;
  background-color: rgb(255, 236, 211);
  border-left: 2px solid rgb(255, 236, 211);
}

.actions-settings-input:focus {
  outline-color: var(--secondary-color);
  background-color: var(--ground_color);
  /* border-left: 2px solid var(--disable-color);
  border-right: 2px solid var(--disable-color); */
}

.actions-settings-textarea {
  height: 150px;
  padding: 7px 10px;
  font-size: 15px;
  border: none;
  background-color: rgb(255, 236, 211);
  border-left: 2px solid rgb(255, 236, 211);
  font-family: inherit;
  resize: none;
}

.actions-settings-textarea:focus {
  outline-color: var(--secondary-color);
  background-color: var(--ground_color);
}

.action-settings-btn-box {
  position: fixed;
  top: 110px;
  right: 0;
  z-index: 1;
}

/* Action Description Styles */

.action-description h1 {
  margin-bottom: 20px;
  text-align: center;
}

.action-description p {
  margin-bottom: 10px;
}

.action-description ol,
.action-description ul {
  margin-left: 40px;
  margin-bottom: 10px;
}

.action-description ol li,
.action-description ul li {
  margin-bottom: 5px;
}

.action-description img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.actions-html a {
  margin: 0 5px;
}

.action-csv-container {
  display: flex;
  column-gap: 10px;
  margin-left: 20px;
  align-items: center;
  width: 80px;
}

.action-star {
  cursor: pointer;
  font-size: 18px;
}

.action-star:hover {
  color: var(--secondary-color);
}

.action-star-active {
  color: var(--secondary-color);
  cursor: pointer;
  font-size: 21px;
}

@media (min-height: 860px) and (min-width: 768px) {
  .action-logs-table-body {
    max-height: 55vh;
  }
}

@media (min-width: 768px) {
  .action-pagination-box {
    margin-right: 0;
  }
  .action-settings-box {
    margin: 20px;
  }
}

@media (min-width: 1024px) {
  .action-settings-param {
    display: flex;
    flex-wrap: nowrap;
    width: 420px;
  }

  .action-settings-value {
    width: 280px;
    height: fit-content;
  }
}
