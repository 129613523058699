.userBox {
  position: relative;
}

.userContainer {
  display: none;
  align-items: center;
  color: var(--primary-color);
  user-select: none;
}

.userContainerMobile {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  user-select: none;
}

.userListActive {
  visibility: visible !important;
  opacity: 1 !important;
}

.userData {
  display: none;
  font-size: 12px;
}

.userDataInfo {
  word-wrap: break-word;
  word-break: break-all;
}

.userAccessList {
  top: 100%;
  right: 0;
  width: 280px;
  max-height: 500px;
  overflow-y: auto;
  position: absolute;
  background-color: var(--axis-background);
  list-style: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 0 2px 2px rgb(0 0 0 / 20%);
  z-index: 12;
}

.userAccessListRow {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 15px;
}

.userAccessListRow:hover {
  background-color: rgb(245, 231, 224);
}

.userAccessListName {
  padding: 10px;
  font-size: 13px;
  color: var(--disable-color);
}

.userAccessListDevider {
  height: 1px;
  width: 100%;
  background-color: var(--disable-color);
}

.userAccessListIconBox {
  margin-left: auto;
}

.userAccessListIcon {
  width: 18px;
  height: 18px;
}

.userAccessListIconCheck {
  width: 18px;
  height: 18px;
  color: var(--success-color);
}

@media (min-width: 768px) {
  .logoutIcon {
    display: none;
  }

  .userData {
    display: block;
  }

  .userContainer {
    display: flex;
    max-width: 220px;
    margin-right: 20px;
  }

  .userContainerMobile {
    display: none;
  }

  .userListActive {
    visibility: hidden !important;
    opacity: 0 !important;
  }

  .userBox:hover .userAccessList {
    visibility: visible !important;
    opacity: 1 !important;
  }

  .hideMobile {
    display: none;
  }
}
