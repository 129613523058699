.button {
  border-radius: 20px;
  border: 1px solid #ee7a00;
  background-color: #ee7a00;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
  margin-top: 30px;
}

.button:active {
  transform: scale(0.95);
}

.button:focus {
  outline: none;
}

.button:disabled {
  background-color: var(--disable-color);
  border: 1px solid var(--disable-color);
  color: var(--ground_color);
  cursor: not-allowed;
}
