/* Navigation */
.nav-box {
  display: flex;
}

.nav-top {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  background-color: var(--ground_color);
  z-index: 10;
  box-shadow: 0 4px 4px -2px var(--disable-color);
}

.nav-left {
  position: fixed;
  display: none;
  top: 0;
  height: 100%;
  width: 55px;
  z-index: 11;
  background-color: var(--primary-color);
  color: var(--ground_color);
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.nav-left-full {
  width: 250px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  position: fixed;
  display: none;
  top: 0;
  height: 100%;
  z-index: 11;
  background-color: var(--primary-color);
  color: var(--ground_color);
}

.nav-left-header {
  margin-top: 10px;
  margin-bottom: 20px !important;
}

.nav-left-body {
  width: 55px;
  height: 85%;
  overflow-y: auto;
  scrollbar-width: none;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.nav-left-body::-webkit-scrollbar {
  display: none;
}

.nav-left-body-full {
  width: 250px;
  height: 85%;
  overflow-y: auto;
  scrollbar-width: none;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.nav-left-body-full::-webkit-scrollbar {
  display: none;
}

.nav-user {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.nav-user-name {
  display: none;
}

.nav-logo {
  display: flex;
  flex: 1;
  justify-content: center;
}

.nav-logo img {
  margin-top: 5px;
  width: 140px;
  height: auto;
}

.nav-icons-menu {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.dashboard-body {
  padding: 20px;
}

.dashboard-gray {
  background-color: rgb(250, 250, 250);
}

.nav-left-option-header {
  display: flex;
  align-items: center;
  width: 250px;
  padding: 0 15px;
  height: 40px;
  min-height: 40px;
  cursor: pointer;
  color: var(--ground_color);
}

.nav-left-option {
  display: flex;
  align-items: center;
  width: 250px;
  padding: 0 15px;
  height: 40px;
  min-height: 40px;
  fill: gray;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  color: var(--ground_color);
}

.nav-left-option:hover {
  background-color: rgb(88, 88, 88);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.nav-left-arrow {
  margin-left: auto !important;
  font-size: 18px !important;
  margin-left: 10px;
  color: var(--ground_color);
}

.nav-left-separator {
  height: 20px;
  min-height: 20px;
  width: 100%;
}

.nav-left-option-list {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.nav-left-list {
  display: block;
  background-color: rgb(134, 134, 134);
  list-style: none;
  font-size: 14px;
}

.nav-left-list-disabled {
  display: none;
}

.nav-left-list li {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  fill: gray;
  cursor: pointer;
}

.nav-left-list li:hover {
  background-color: var(--secondary-color);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.nav-left-icon {
  font-size: 25px !important;
  margin-right: 15px;
  color: var(--ground_color);
}

.nav-left-img {
  margin-right: 15px;
  width: 25px;
  height: auto;
}

.nav-left-active li {
  background-color: var(--secondary-color);
}

.nav-left-active li:hover {
  background-color: var(--secondary-color);
}

.nav-left-main-active {
  background-color: var(--secondary-color);
}

.nav-left-main-active:hover {
  background-color: var(--secondary-color);
}

.nav-left-close {
  display: flex;
  justify-content: flex-end;
}

.nav-left-close-icon {
  font-size: 35px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.nav-left-close-icon path {
  stroke: var(--ground_color);
}

.nav-news-box {
  position: relative;
  margin-right: 15px;
}

.new-count-icon {
  position: absolute;
  bottom: 8px;
  right: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--error-color);
}

.nav-top-mobile {
  display: block;
}

.active-menu {
  display: block;
}

/* Breadcrumbs */
.breadcrumbs-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
  min-height: 50px;
  background-color: var(--axis-background);
}

.breadcrumbs-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px;
  font-size: 16px;
  font-weight: 600;
}

.breadcrumbs-item {
  margin: 5px 0;
}

.breadcrumbs-link {
  color: var(--primary-color);
}

.breadcrumbs-separator {
  margin: 0 5px;
}

.breadcrumbs-add-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  cursor: pointer;
  color: var(--primary-color);
}

.breadcrumbs-end-name {
  font-weight: 400;
}

.dashboard-actions-list {
  margin-bottom: 30px;
  border-left: 2px solid var(--disable-color);
  border-top: 2px solid var(--disable-color);
}

.dashboard-actions-row {
  display: flex;
  min-height: 40px;
}

.dashboard-actions-name {
  width: 250px;
  padding: 5px 10px;
  border-bottom: 2px solid var(--disable-color);
  border-right: 2px solid var(--disable-color);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
}

.dashboard-actions-status {
  width: 70px;
  border-bottom: 2px solid var(--disable-color);
  border-right: 2px solid var(--disable-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.dashboard-actions-icon {
  margin-right: 20px;
  font-size: 25px;
}

.dashboard-actions-logo-box {
  display: flex;
  width: 100px;
  justify-content: center;
}

.dashboard-actions-logo {
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.dashboard-actions-status-icon {
  height: 25px;
  width: auto;
}

.dashboard-actions-name-center {
  justify-content: center;
}

.dashboard-actions-header {
  border: 2px solid var(--disable-color);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  width: 320px;
  margin-bottom: 10px;
}

.dashboard-feed-header {
  display: grid;
  grid-template-columns: 170px 75px 75px;
  border-left: 2px solid var(--disable-color);
  border-top: 2px solid var(--disable-color);
  height: 52px;
  margin-top: 10px;
  font-size: 13px;
}

.dashboard-feed-row {
  display: grid;
  grid-template-columns: 170px 75px 75px;
  border-left: 2px solid var(--disable-color);
  min-height: 40px;
  font-size: 13px;
}

.dashboard-feed-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--disable-color);
  border-bottom: 2px solid var(--disable-color);
  padding: 5px;
}

.dashboard-feed-cell-left {
  justify-content: left !important;
}

.dashboard-actions-link:hover {
  background-color: var(--axis-background) !important;
}

.dashboard-card {
  display: grid;
  width: fit-content;
  margin: 20px 0;
  padding: 10px;
  background-color: #fff;
  border: none;
  box-shadow: 0 0 8px 0 rgb(195 195 195 / 50%);
  border-radius: 5px;
}

@media (min-width: 768px) {
  .nav-icons-menu {
    min-width: 180px;
  }

  .nav-top {
    padding-left: 250px;
  }

  .dashboard-body {
    padding-left: 90px;
  }

  .nav-left {
    display: block;
  }

  .nav-left-full {
    display: block;
  }

  .nav-left-body {
    display: flex;
    flex-direction: column;
  }

  .nav-left-body-full {
    display: flex;
    flex-direction: column;
  }

  .nav-user-name {
    display: block;
  }

  .breadcrumbs-container {
    flex-direction: row;
    padding-left: 90px;
    padding-right: 20px;
  }

  .breadcrumbs-add-btn {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  .nav-top-mobile {
    display: none;
  }

  .nav-left-close {
    display: none;
  }

  .nav-left-header {
    margin-top: 20px;
    margin-bottom: 30px !important;
  }

  .nav-logo img {
    width: 200px;
    height: auto;
  }

  .dashboard-card {
    padding: 20px;
  }
}

@media (min-width: 1024px) {
  .dashboard-actions-name {
    width: 300px;
    font-size: 15px;
  }

  .dashboard-actions-row-header {
    height: 50px;
  }

  .dashboard-actions-header {
    width: 370px;
  }

  .dashboard-actions-header-feed {
    width: 420px;
  }

  .dashboard-feed-row {
    grid-template-columns: 240px 90px 90px;
    font-size: 14px;
  }

  .dashboard-feed-header {
    grid-template-columns: 240px 90px 90px;
    font-size: 15px;
  }
}
